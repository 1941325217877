import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import './footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div>&copy; 2023 Ringarens Hotell</div>
      <div>
        <PhoneIcon fontSize='small' />
        <a href="tel:+46762787701">0762-78 77 01</a>
      </div>
      <div>
        <EmailIcon fontSize='small' />
        <a href="mailto:info@uthyrningvisby.se">info@uthyrningvisby.se</a>
      </div>
    </footer>
  )
}

export default Footer