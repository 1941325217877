import React from 'react'

import Carousel from '../components/carousel/carousel';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import useDeviceSize from '../hooks/use-device-size';

import { apartmentData } from '../data/apartments'

import './apartments.css'
import Apartment from '../components/apartment/apartment';

const Apartments = () => {
  const { isMobile } = useDeviceSize();

  return (
    <section className='route apartments'>
      <div className="apartments__wrapper">
        {apartmentData.map(apartment => (
          <article key={apartment.number}>
            <Card>
              {
                isMobile && (
                  <CardMedia
                    children={<Carousel className="carousel--mobile" images={apartment.images} />}
                  />
                )
              }
              <CardContent>
                <Apartment apartment={apartment} />
              </CardContent>
            </Card>
          </article>
        ))}
      </div>
    </section>
  )
}

export default Apartments