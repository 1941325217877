import React from 'react'

import './home.css';

const Home = () => {
  return (
    <section className='route home'>
      <div className="home__image">
        <div className="home__welcome">
          <h2>Varmt välkommen till Ringarens Lägenhetshotell</h2>
          <p>Din hemvist i hjärtat av Visby - en strålande pärla på den charmiga ön Gotland.</p>
          <p>Här, centralt beläget i Visbys livliga innerstad, är du omgiven av en unik kombination av frodig natur och en fascinerande medeltida historia. Visby, känd för sin välbevarade ringmur och medeltida kyrkoruinerna, är en plats där historien lever och andas i varje kullerstensgata och gränd. Oavsett om du vill uppleva stadens liv och rörelse eller utforska det enastående landskapet som omger oss, erbjuder vårt läge oöverträffad tillgång till allt det bästa som Visby och Gotland har att erbjuda.</p>
          <p>På Ringarens Lägenhetshotell erbjuder vi ett utmärkt utbud av lägenheter för uthyrning. Varje lägenhet är smakfullt inredd och utrustad med allt du behöver för en bekväm och minnesvärd vistelse. Vare sig du är här för en romantisk helg, en familjesemester eller en affärsresa, erbjuder vi ett boende som passar dina behov.</p>
          <p>Vårt centralt belägna lägenhetshotell ger dig möjlighet att uppleva Gotlands naturskönhet och Visbys rika historia direkt utanför din dörr. Och inomhus, är ditt välbefinnande och komfort alltid vår högsta prioritet.</p>
          <p>Vi ser fram emot att välkomna dig till Ringarens Lägenhetshotell – din perfekta utgångspunkt för att utforska och njuta av Visby och Gotland.</p>
        </div>
      </div>
    </section>
  )
}

export default Home