import React from 'react';
import './admin.css';

const Admin = () => {
  return (
    <section className='route admin'>
      <h2>Admin</h2>
    </section>
  )
}

export default Admin