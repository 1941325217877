import React from 'react';
import { NavLink } from 'react-router-dom';

import './navigation-header.css';

const links = [
  {
    to: '/history',
    text: 'Historia',
  },
  {
    to: '/apartments',
    text: 'Lägenheter',
  },
  {
    to: '/rental-information',
    text: 'Hyresvillkor',
  },
  {
    to: '/contact',
    text: 'Kontakt',
  },  
  {
    to: '/reservation',
    text: 'Boka',
  },
];

const NavigationHeader = () => {
  return (
    <nav className="navigation_header">
      <div className="navigation_header__logo">
        <NavLink to="/">
          <span className="title-start">Ringarens</span>
          <span className="title-end">Lägenhetshotell</span>
        </NavLink>
      </div>
      <ul>
        {
          links.map(link => (
            <li key={link.text}>
              <NavLink to={link.to}>{link.text}</NavLink>
            </li>
          ))
        }
      </ul>
    </nav>
  );
}

export default NavigationHeader;
