import React, {
  useCallback,
  useState
} from 'react';
import {
  TextField,
  Button,
} from '@mui/material';

import './contact.css';
import API_BASE_URL from '../config';

const Contact = () => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    message: '',
    firstName: '',
    lastName: '',
    email: ''
  });

  const validate = useCallback(() => {
    let newErrors = {};

    // Message validation
    if (!formData.message.trim()) {
      newErrors.message = 'Ange ett meddelande';
    }

    // Name validation
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'Ange ett förnamn';
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Ange ett efternamn';
    }

    // E-mail validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const hasEmailError = !emailRegex.test(formData.email);
    if (hasEmailError) {
      newErrors.email = 'Ange en giltig e-postadress (ex namn@domain.se)';
    }

    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    return isValid;
  }, [formData.email, formData.firstName, formData.lastName, formData.message]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      fetch(`${API_BASE_URL}/api/utils/send-mail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        credentials: 'include',
        body: JSON.stringify({
          ...formData,
          subject: 'Kontaktformulär'
        })
      })
        .then(response => response.json())
        .then(data => {
          // FIXME: Replace with a proper alert or notification
          alert('Ditt meddelande har skickats!')
          setFormData({
            message: '',
            firstName: '',
            lastName: '',
            email: ''
          })
        })
    } else {
      console.log('not valid form')
    }
  };

  return (
    <section className='route contact'>
      <p>
        Vi uppskattar din kontakt med oss!
      </p>
      <p>För att säkerställa snabbast möjliga svar, föredrar vi kommunikation via e-post.
        Vänligen fyll i formuläret nedan med din förfrågan eller meddelande, och vi återkommer till dig så snart som möjligt.
      </p>

      <form onSubmit={handleSubmit}>
        <TextField
          name="firstName"
          label="Förnamn"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.firstName)}
          helperText={errors.firstName}
        />
        <TextField
          name="lastName"
          label="Efternamn"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.lastName)}
          helperText={errors.lastName}
        />
        <TextField
          className="input__email"
          name="email"
          label="Epost"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <TextField
          className="input__message"
          name="message"
          label="Meddelande"
          value={formData.message}
          minRows={5}
          onChange={handleChange}
          fullWidth
          multiline
          error={Boolean(errors.message)}
          helperText={errors.message}
          margin="normal"
        />

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
          Skicka
        </Button>
      </form>

    </section>
  )
}

export default Contact