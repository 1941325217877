import React from 'react'
import useDeviceSize from '../../hooks/use-device-size';
import { useNavigate } from 'react-router-dom';
import Carousel from '../carousel/carousel';
import ApartmentExtraInfo from '../apartment-extra-info/apartment-extra-info';
import BedAvailability from '../bed-availablility/bet-availability';
import { Button } from '@mui/material';

import './apartment.css';


const Apartment = ({ apartment, withReservation = false, reservationPrice, selectedWeek, futureWeek, selectedYear, guestCount }) => {
  const { isTablet, isDesktop } = useDeviceSize();
  const navigate = useNavigate();
  const {
    id: propertyId,
    number,
    description,
    size,
    rooms,
    beds,
    extraBeds,
    extraInfo,
    weeklyPrice,
    images,
  } = apartment;

  const bookingFormQueryString = `startWeek=${selectedWeek}&endWeek=${futureWeek}&year=${selectedYear}&minBeds=${guestCount}`;

  const handleBookingClick = (propertyId) => {
    navigate(`/booking/${propertyId}?${bookingFormQueryString}`);
  }

  return (
    <div className='apartment__wrapper'>
      {(isTablet || isDesktop) && (
        <Carousel className="carousel--desktop" images={images} />
      )}

      <div className='apartment'>
        <div className="apartment__header">
          <div>
            <div className="apartment__title">Lägenhet {number}</div>

            <div className="apartment__stats">
              <div className='apartment__size'>
                {size} m2
              </div>
              <div className="apartment__beds">
                <BedAvailability beds={beds} extraBeds={extraBeds} />
              </div>
            </div>
          </div>
          {withReservation && (
            <Button
              className="apartment__reserve_btn"
              variant="contained"
              color="primary"
              onClick={
                () => handleBookingClick(propertyId)
              }>
              Boka
            </Button>
          )}

        </div>

        <ApartmentExtraInfo extraInfo={apartment.extraInfo} />

        <div className='apartment__description'>
          {Array.isArray(description) && description.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>

        <ul className='apartment__properties'>
          <li>Antal rum: {rooms}</li>
          <li>Antal bäddar: {beds}</li>
          {extraBeds && (
            <li>Antal extrabäddar: {extraBeds}</li>
          )}
          {reservationPrice && (
            <li>Pris: {reservationPrice} kr</li>
          )}
          {!reservationPrice && (
            <li>Pris från: {weeklyPrice} kr</li>
          )}
        </ul>

      </div>

    </div>
  )
}

export default Apartment