import React from 'react';
import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';
import Paper from '@mui/material/Paper';
import {
  getSelectedDateFromSelectedWeek,
  getFutureDateFromFutureWeek
} from '../../utils/date-utils';

import './search-description.css';


const SearchDescription = ({ isRequest = false, selectedDate, futureDate, selectedWeek, futureWeek, weekCount, guestCount, selectedYear }) => {
  const _selectedDate = selectedDate || getSelectedDateFromSelectedWeek(selectedYear, selectedWeek);
  const _futureDate = futureDate || getFutureDateFromFutureWeek(selectedYear, selectedWeek, futureWeek);

  return (
    <Paper className="search-description" elevation={2}>
      <div>
        {weekCount === 1
          ? `Aktuell ${isRequest ? 'förfrågan' : 'sökning'} gäller för ${guestCount} person${guestCount === 1 ? '' : 'er'}, vecka ${selectedWeek}.`
          : `Aktuell ${isRequest ? 'förfrågan' : 'sökning'} gäller för ${guestCount} person${guestCount === 1 ? '' : 'er'}, veckorna ${selectedWeek} till ${futureWeek}.`
        }
      </div>
      <div>
        {format(_selectedDate, 'eee d MMMM, yyyy', { locale: sv })}
        {` - `}
        {format(_futureDate, 'eee d MMMM, yyyy', { locale: sv })}
      </div>
    </Paper>
  )
}

export {
  getSelectedDateFromSelectedWeek,
  getFutureDateFromFutureWeek
}

export default SearchDescription