import React from 'react'
import './rental-information.css'

import { rentalAgreement } from '../data/rental-agreement';

const RentalInformation = () => {
  return (
    <section className="route rental-information">
      <h2>Hyresvillkor</h2>
      {rentalAgreement.map(({sectionHeader, sectionItems}, headerIndex) => (
        <div key={headerIndex}>
          <h3>{sectionHeader}</h3>
          <ul>
            {sectionItems.map((item, itemIndex) => (
              <li key={itemIndex}>{item}</li>
            ))}
          </ul>
        </div>
      ))
      }
    </section>
  )
}

export default RentalInformation