import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { useSearchContext } from '../context/SearchContext';

import { FormControl, InputLabel, MenuItem, Select, Button } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { addWeeks, addDays, getWeek, getISOWeek } from 'date-fns';
import SearchDescription from '../components/search-description/search-description';
import sv from 'date-fns/locale/sv';
import { apartmentData } from '../data/apartments'
import Apartment from '../components/apartment/apartment';

import Carousel from '../components/carousel/carousel';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import useDeviceSize from '../hooks/use-device-size';


import './reservation.css'
import API_BASE_URL from '../config';

const MinAmountOfGuests = 1;
const MaxAmountOfGuests = 4;

const FIRST_RENTAL_WEEK = 24; // sunday of the week before 25
const LAST_RENTAL_WEEK = 32; // sunday of the week before 33

const selectableAmountOfGuests = () => {
  const amountOfGuests = [];

  for (let i = MinAmountOfGuests; i <= MaxAmountOfGuests; i++) {
    amountOfGuests.push(i);
  }

  return amountOfGuests;
};

const getMaxSelectableWeeks = (date) => {
  const weekNumber = getWeek(date, { locale: sv });
  // Calculate how many weeks can be selected without exceeding week 34
  const weeksTillEnd = LAST_RENTAL_WEEK + 1 - weekNumber;
  // Limit the maximum to 4 or the number of weeks till the end, whichever is smaller

  return Math.min(4, weeksTillEnd);
};

const disableDates = (date) => {
  const today = new Date();
  // Ta bort tidsdelen för att undvika fel vid jämförelse
  today.setHours(0, 0, 0, 0);

  // Hämta nuvarande och valda datumets veckonummer
  const currentWeek = getWeek(today, { locale: sv });
  const selectedWeek = getWeek(date, { locale: sv });
  const currentYear = today.getFullYear();
  const selectedYear = date.getFullYear();

  // Kolla om datumet är i det förflutna eller inte en söndag
  if (date < today || date.getDay() !== 0) {
    return true;
  }

  // Kontrollera om veckonummer är inom tillåtna gränser
  const isWithinAllowedWeeks = selectedWeek >= FIRST_RENTAL_WEEK && selectedWeek <= LAST_RENTAL_WEEK;

  // Om vi är efter vecka 33 och datumet är för mer än nästa år, förhindra val
  if (currentWeek > LAST_RENTAL_WEEK && selectedYear > currentYear + 1) {
    return true;
  }

  // Om vi är efter vecka 33 detta år, tillåt endast val för nästa år
  if (currentWeek > LAST_RENTAL_WEEK && selectedYear === currentYear + 1) {
    return !isWithinAllowedWeeks;
  }

  // Om vi är före vecka 33 detta år, tillåt val inom de tillåtna veckorna
  if (currentWeek <= LAST_RENTAL_WEEK && selectedYear === currentYear) {
    return !isWithinAllowedWeeks;
  }

  // I övriga fall, förhindra val
  return true;
};

const getNextSunday = () => {

  const today = new Date();

  // Sätt startdatumet till idag eller nästa söndag
  let nextSunday = new Date(today);
  if (today.getDay() !== 0) {
    let daysUntilNextSunday = 7 - today.getDay();
    nextSunday.setDate(today.getDate() + daysUntilNextSunday);
  }

  // Sök nästa söndag som inte är "disabled"
  while (disableDates(nextSunday)) {
    nextSunday.setDate(nextSunday.getDate() + 7);

    // Om nästa söndag är utanför tillåtet intervall, gå till nästa år
    if (getWeek(nextSunday, { locale: sv }) > LAST_RENTAL_WEEK) {
      nextSunday.setFullYear(nextSunday.getFullYear() + 1);
      nextSunday.setMonth(0); // Sätt månad till januari
      nextSunday.setDate(1); // Sätt datum till första dagen i månaden

      while (getWeek(nextSunday, { locale: sv }) < FIRST_RENTAL_WEEK) {
        nextSunday.setDate(nextSunday.getDate() + 1);
      }

      // Sätt till nästa söndag
      nextSunday.setDate(nextSunday.getDate() + (7 - nextSunday.getDay()));
    }
  }

  return nextSunday;
};

const calculateMinMaxDates = () => {
  const today = new Date();
  const currentWeek = getWeek(today, { locale: sv });
  let minDate, maxDate;

  // If it's past week 33, the user can select dates for the next year.
  if (currentWeek > LAST_RENTAL_WEEK) {
    const nextYear = today.getFullYear() + 1;
    // Set minDate to the first Sunday of week 25 next year
    minDate = new Date(nextYear, 0, 1);
    while (getWeek(minDate, { locale: sv }) < FIRST_RENTAL_WEEK || minDate.getDay() !== 0) {
      minDate.setDate(minDate.getDate() + 1);
    }

    // Set maxDate to the last Sunday of week 33 next year
    maxDate = new Date(nextYear, 0, 1);
    while (getWeek(maxDate, { locale: sv }) <= LAST_RENTAL_WEEK) {
      maxDate.setDate(maxDate.getDate() + 1);
    }
    maxDate.setDate(maxDate.getDate() - maxDate.getDay()); // Go back to the last Sunday
  } else {
    // Set minDate to today or the next Sunday if today is not Sunday
    minDate = today.getDay() === 0 ? today : getNextSunday();
    // Set maxDate to the last Sunday of week 33 this year
    maxDate = new Date(today.getFullYear(), 0, 1);
    while (getWeek(maxDate, { locale: sv }) <= LAST_RENTAL_WEEK) {
      maxDate.setDate(maxDate.getDate() + 1);
    }
    maxDate.setDate(maxDate.getDate() - maxDate.getDay()); // Go back to the last Sunday
  }

  return { minDate, maxDate };
};

const { minDate, maxDate } = calculateMinMaxDates();

const getApartmentData = (number) => {
  const apartment = apartmentData.find((apartment) => apartment.number === number);
  return apartment;
};

const Reservation = () => {
  const {
    searchResults, setSearchResults,
    searchInputs, setSearchInputs,
    guestCount, setGuestCount,
    weekCount, setWeekCount,
    maxWeeks, setMaxWeeks,
    selectedDate, setSelectedDate
  } = useSearchContext();

  const { isMobile } = useDeviceSize();

  const [_selectedDate, _setSelectedDate] = useState(selectedDate || getNextSunday());

  React.useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(getNextSunday());
    };

    if (!weekCount) {
      setWeekCount(1);
    };

    if (!guestCount) {
      setGuestCount(MinAmountOfGuests);
    };

    if (!maxWeeks) {
      setMaxWeeks(getMaxSelectableWeeks(selectedDate));
    };

    if (!searchResults) {
      setSearchInputs({})
    }
  }, [guestCount, maxWeeks, searchResults, selectedDate, setGuestCount, setMaxWeeks, setSearchInputs, setSelectedDate, setWeekCount, weekCount])


  const futureDate = addWeeks(selectedDate, weekCount);
  const selectedWeek = getISOWeek(addDays(selectedDate, 1));
  const futureWeek = weekCount > 1 ? getISOWeek(futureDate) : selectedWeek;
  const selectedYear = addDays(selectedDate, 1).getFullYear();


  const getAvailableProperties = async () => {
    const response = await fetch(`${API_BASE_URL}/api/properties/available?startWeek=${selectedWeek}&endWeek=${futureWeek}&year=${selectedYear}&minBeds=${guestCount}`, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      },
      credentials: 'include',
    });

    return response.json();
  };

  const {
    refetch,
    error,
    isLoading
  } = useQuery(
    ['availableProperties'],
    getAvailableProperties,
    {
      // keepPreviousData: true, // This means that if the query is refetched, the old data will still be displayed until the new data is available
      enabled: false,  // This means the query won't run until we manually trigger it
      onSuccess: (data) => {
        setSearchResults(data);
        setSearchInputs((prev) => ({
          ...prev,
          selectedDate,
          futureDate,
          selectedWeek,
          futureWeek,
          weekCount,
          guestCount,
          selectedYear
        }));
      }
    }
  );

  const handleGuestCountChange = (event) => {
    setGuestCount(event.target.value);
  }

  const handleDateChange = (date) => {
    _setSelectedDate(date);
  };

  const onDateClosed = () => {
    _setSelectedDate(selectedDate);
  }

  const onDateAccepted = () => {
    setSelectedDate(_selectedDate);
    // After changing the date, recalculate the maximum number of weeks that can be selected
    setMaxWeeks(getMaxSelectableWeeks(_selectedDate));
    // If the current week count exceeds the new max, adjust it down
    setWeekCount((prev) => Math.min(prev, getMaxSelectableWeeks(_selectedDate)));
  }

  const handleWeekCountChange = (event) => {
    setWeekCount(event.target.value);
  }

  // Generate the selectable options based on the maxWeeks
  const weekOptions = [];
  for (let i = 1; i <= maxWeeks; i++) {
    weekOptions.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }

  const hasInputChanged = React.useMemo(() => {
    return (
      searchInputs.selectedDate !== selectedDate ||
      searchInputs.weekCount !== weekCount ||
      searchInputs.guestCount !== guestCount
    )
  }, [guestCount, searchInputs.guestCount, searchInputs.selectedDate, searchInputs.weekCount, selectedDate, weekCount])

  const isValidSearch = Object.keys(searchInputs).length > 0;

  return (
    <section className="route reservation">

      <div className="reservation__inputs">
        <FormControl>
          <MobileDatePicker
            label="Ankomstdatum"
            value={_selectedDate || getNextSunday()}
            onAccept={onDateAccepted}
            onChange={handleDateChange}
            onClose={onDateClosed}
            shouldDisableDate={disableDates}
            minDate={minDate}
            maxDate={maxDate}
          />
        </FormControl>

        <FormControl>
          <InputLabel id="weeks-select-label">Antal veckor</InputLabel>
          <Select
            autoWidth
            labelId="weeks-select-label"
            id="weeks-select"
            value={weekCount || 1}
            label="Antal veckor"
            onChange={handleWeekCountChange}
          >
            {weekOptions}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="guest-count-label">Antal gäster</InputLabel>
          <Select
            autoWidth
            labelId="guest-count-label"
            id="guest-count"
            value={guestCount || 1}
            label="Antal gäster"
            onChange={handleGuestCountChange}
          >
            {selectableAmountOfGuests().map(amountOfGuests => (
              <MenuItem key={amountOfGuests} value={amountOfGuests}>{amountOfGuests}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {isValidSearch && (
        <SearchDescription
          {...searchInputs}
        />
      )}

      <Button
        variant={searchResults.length > 0 && !hasInputChanged ? 'outlined' : 'contained'}
        onClick={() => refetch()}
      >
        {searchResults.length && hasInputChanged ? 'Uppdatera sökning' : 'Sök'}
      </Button>

      <div className="reservation__results">
        {isLoading && (
          <p>Hämtar resultat...</p>
        )}

        {error && (
          <p>Fel: {error.message}</p>
        )}

        {/* TODO: Clean this up */}
        {isValidSearch && searchResults && searchResults.length === 0 && (
          <p>Inga tillgängliga lägenheter hittades för de valda veckorna och antalet gäster.</p>
        )}

        {searchResults && (
          <ul className='available-properties-list'>
            {searchResults.map((property) => {
              const apartment = getApartmentData(property.number);

              return (
                <li key={property.number}>

                  <div className="apartments__wrapper">
                    <article>
                      <Card>
                        {
                          isMobile && (
                            <CardMedia
                              children={<Carousel className="carousel--mobile" images={apartment.images} />}
                            />
                          )
                        }
                        <CardContent>
                          <Apartment
                            apartment={apartment}
                            withReservation
                            reservationPrice={property.total_price}
                            selectedWeek={selectedWeek}
                            futureWeek={futureWeek}
                            selectedYear={selectedYear}
                            guestCount={guestCount}
                          />
                        </CardContent>
                      </Card>
                    </article>
                  </div>

                </li>
              )
            })}
          </ul>
        )}
      </div>
    </section>
  )
}

export default Reservation;
