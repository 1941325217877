import React, { createContext, useState } from 'react';

const SearchContext = createContext();

export default SearchContext;

export const SearchProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchInputs, setSearchInputs] = useState({});
  const [guestCount, setGuestCount] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [weekCount, setWeekCount] = React.useState(null);
  const [maxWeeks, setMaxWeeks] = React.useState(null);

  const context = React.useMemo(() => ({
    searchResults,
    setSearchResults,
    searchInputs,
    setSearchInputs,
    guestCount,
    setGuestCount,
    selectedDate,
    setSelectedDate,
    weekCount,
    setWeekCount,
    maxWeeks,
    setMaxWeeks
  }), [searchResults, searchInputs, guestCount, selectedDate, weekCount, maxWeeks]);

  return (
    <SearchContext.Provider value={context}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      'useSearchContext must be used within a SearchProvider'
    );
  }
  return context;
};
