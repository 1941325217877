import React from "react";
import "./apartment-extra-info.css";

const ApartmentExtraInfo = ({ extraInfo = [] }) => {
  if (!extraInfo.length) return null;

  return (
    <ul className="apartment-extra-info">
      {
        extraInfo.map((info, index) => <li className="apartment-extra-info__item" key={index}>{info}</li>)
      }
    </ul>
  );
}

export default ApartmentExtraInfo
