import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';

import './carousel.css';

import { Zoom, Pagination } from 'swiper/modules';

const Carousel = ({ className, images = [] }) => {
  const customPagination = {
    "clickable": true,
    "renderBullet": function (index, className) {
      return '<div class="' + className + '">' + (index + 1) + '</div>';
    }
  }

  return (
    <div className={`carousel ${className ? className : ''}`.trim()}>
      <Swiper
        pagination={customPagination}
        modules={[Zoom, Pagination]}
        className="carousel_swiper"
        spaceBetween={12}
        zoom={true}
      >
        {images.map(({ url, alt }, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-zoom-container">
              <img className='swiper__img' src={url} alt={alt} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Carousel