const rentalAgreement = [
  {
    sectionHeader: 'Incheckning och utcheckning',
    sectionItems: [
      'Våra lägenheter kan bokas per vecka under sommarperioden. Bytesdag är söndagar med incheckning från kl 15 och utcheckning kl 12.',
      'Vid önskemål om annan in- eller utcheckningstid, kontakta oss för att se vad som är möjligt. Vi försöker tillgodose era önskemål så långt det är möjligt.',
      'Utanför sommarperioden kan uthyrning erbjudas på vissa lägenheter. Kontakta oss för att se vad som gäller vid önskat hyresdatum.',
      'Vid avlastning från bil så ber vi att ni inte blockerar för grannhusets ingång'
    ]
  },
  {
    sectionHeader: 'Bokningsavgift och hyra',
    sectionItems: [
      'Vid bokning så krävs en anmälningsavgift på 2000 kr som fungerar som en handpenning. Den ska betalas inom 10 dagar från bokningsdatumet eller direkt om ankomstdatum är inom 10 dagar vid bokningstillfället.',
      'Återstående hyreskostnad ska betalas senast 40 dagar innan ankomstdagen.',
      'En bokning är personlig men kan överlåtas till annan med vårt godkännande.'
    ]
  },
  {
    sectionHeader: 'Bokning och Betalning',
    sectionItems: [
      'Vid betalning, ange namn och fakturanummer.',
      'Ett tips är att boka båtbiljetter i god tid för att säkerställa önskad avgångstid.'
    ]
  },
  {
    sectionHeader: 'Avbokning',
    sectionItems: [
      'Vid avbokning inom 40 dagar av ankomstdatumet så återbetalas ej bokningsavgiften.',
      'Vid avbokning inom 10 dagar av ankomstdatumet så återbetalas 50% av totala hyreskostnaden.'
    ]
  },
  {
    sectionHeader: 'Inför ankomst',
    sectionItems: [
      'Nyckel kan hämtas mellan kl 15-16. Vid senare ankomst, kontakta oss för att bekräfta en annan tid.',
      'Vid borttappad nyckel så tillkommer en avgift på 1500 kr per nyckel.'
    ]
  },
  {
    sectionHeader: 'Lägenheter',
    sectionItems: [
      'Alla våra lägenheter på Vårdklockegatan 6 och 8 klassas som hotell och uppfyller alla brand-, larm- och utrymningskrav.',
      'Sängkläder och handdukar går att hyra för 250 kr / set.',
      'Barnstol och barnsäng kan tillhandahållas vid behov.',
      'Parkering ingår ej. Det finns parkeringsmöjligheter inom 5 minuters promenad.',
      'Lastning och lossning direkt vid fastigheten är tillåtet.',
      'Alla lägenheter har tillgång till Wifi.',
      'Alla lägenheter är rökfria.',
      'Inga husdjur är tillåtna i lägenheterna.',
      'Städning ingår ej i hyran. Städning kan beställas för per tillfälle. Kostnad är från 800 kr beroende på storlek på lägenheten.'
    ]
  },
  {
    sectionHeader: 'Lugnt och trivsamt boende',
    sectionItems: [
      'Vår ambition är att erbjuda ett lugnt och trivsamt boende och önskar därför inte några livliga fester.'
    ]
  }
];

export {
  rentalAgreement
}
