import React from 'react';

import './inquired-property.css'
import BedAvailability from '../bed-availablility/bet-availability';
import SearchDescription from '../search-description/search-description';

const InquiredProperty = ({ property }) => {
  const {
    input_startWeek,
    input_endWeek,
    input_year,
    input_minBeds,
    // description,
    number,
    id,
    size,
    // shortDescription,
    total_beds,
    total_price,
    name
  } = property;

  const isAvailable = Boolean(id);

  return (
    <div className="inquired-property">
      {!isAvailable && (
        <div className="inquired-property__unavailable">
          Lägenheten är inte längre tillgänglig.
        </div>
      )}
      {isAvailable && (
        <>
          <div className="property__image">
            <img className='responsive-image' src={`../images/apartments/${number}/apartment-${number}-livingroom.jpg`} alt={`Lägenhet ${number}`} />
          </div>
          <div>
            <h3>{name}</h3>
            <BedAvailability beds={total_beds} extraBeds={0} size="small" />
            <div className="inquired-property__price">Storlek: {size} m2</div>
            <div className="inquired-property__price">Pris: {total_price} kr</div>
          </div>
          <div className="inquired-property__description">
            <SearchDescription
              isRequest
              selectedWeek={input_startWeek}
              futureWeek={input_endWeek}
              weekCount={input_endWeek - input_startWeek + 1}
              guestCount={input_minBeds}
              selectedYear={input_year}
            />
          </div>
        </>
      )}

    </div>
  )
}

export default InquiredProperty;
