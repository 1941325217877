import React from "react";
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const BedAvailability = ({ beds, extraBeds, size }) => {
  const bedIcons = [];
  for (let i = 0; i < beds; i++) {
    bedIcons.push(<PersonIcon key={i} fontSize={size === 'small' ? 'small' : undefined} />);
  }
  if (extraBeds) {
    for (let i = 0; i < extraBeds; i++) {
      bedIcons.push(<PersonOutlineOutlinedIcon key={i + beds} fontSize={size === 'small' ? 'small' : undefined} />);
    }
  }

  return <div className="bet-availability">{bedIcons}</div>;
}

export default BedAvailability
