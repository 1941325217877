import React from 'react';
import { history } from '../data/history';

import './history.css';

const History = () => {
  return (
    <section className='route history'>
      <p>
        <h2>Wisby Ringaren 17s historia</h2>
      </p>
      <p>
      {history.map((part, i) => (
        <p key={i}>
          {part}
        </p>
      ))}
      </p>
    </section>
  )
}

export default History